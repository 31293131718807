/* purgecss start ignore */
@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-arrow.slick-prev {
  left: 10px;
  z-index: 10;
}
.slick-arrow.slick-next {
  right: 10px;
  z-index: 10;
}

html {
  scroll-behavior: smooth;
}

#main-navbar {
  width: 100%;
}

@screen sm {
  #main-navbar {
    width: calc(100% - 16rem);
  }
}

.masonry {
  column-count: 1;
  column-gap: 2rem;
}

.masonry > div {
  break-inside: avoid;
  page-break-inside: avoid;
  display: inline-block;
  width: 100%;
}

@screen md {
  .masonry {
    column-count: 2;
  }
}

.ql-editor {
  @apply dark:text-gray-400;
  min-height: 200px;
}

div.ql-container.ql-snow,
div.ql-toolbar.ql-snow {
  @apply dark:border-gray-700 border-gray-100;
}

span.ql-header.ql-picker {
  @apply dark:text-gray-400;
}

.ql-snow .ql-stroke {
  @apply dark:text-gray-400 dark:stroke-current;
}

.ql-editor.ql-blank::before {
  @apply dark:text-gray-400 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  @apply dark:bg-gray-800 dark:border-gray-700 !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  @apply dark:border-gray-700 !important;
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  @apply bg-primary-500;
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #0f23ca;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #0f23ca, 0 0 5px #0f23ca;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #0f23ca;
  border-left-color: #0f23ca;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */
.charts-container:after {
  clear: both;
  content: '';
  display: table;
}
.pie-wrapper {
  font-size: 160px;
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative;
}
.pie-wrapper:nth-child(3n + 1) {
  clear: both;
}
.pie-wrapper .pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 1em, 1em, 0.5em);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .pie .half-circle {
  height: 100%;
  width: 100%;
  border: 0.1em solid #3498db;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.pie-wrapper .label {
  background: #34495e;
  border-radius: 50%;
  bottom: 0.4em;
  color: #ecf0f1;
  cursor: default;
  display: block;
  font-size: 0.25em;
  left: 0.4em;
  line-height: 2.8em;
  position: absolute;
  right: 0.4em;
  text-align: center;
  top: 0.4em;
}
.pie-wrapper .label .smaller {
  color: #bdc3c7;
  font-size: 0.45em;
  padding-bottom: 20px;
  vertical-align: super;
}
.pie-wrapper .shadow {
  height: 100%;
  width: 100%;
  border: 0.1em solid #eee;
  border-radius: 50%;
}
.pie-wrapper.style-2 .label {
  background: none;
  color: #7f8c8d;
}
.pie-wrapper.style-2 .label .smaller {
  color: #bdc3c7;
}
.pie-wrapper.progress-30 .pie .half-circle {
  border-color: #3498db;
}
.pie-wrapper.progress-30 .pie .left-side {
  transform: rotate(108deg);
}
.pie-wrapper.progress-30 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-60 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-60 .pie .half-circle {
  border-color: #9b59b6;
}
.pie-wrapper.progress-60 .pie .left-side {
  transform: rotate(216deg);
}
.pie-wrapper.progress-60 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-90 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-90 .pie .half-circle {
  border-color: rgb(72, 187, 120);
}
.pie-wrapper.progress-90 .pie .left-side {
  transform: rotate(324deg);
}
.pie-wrapper.progress-90 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-45 .pie .half-circle {
  border-color: #1abc9c;
}
.pie-wrapper.progress-45 .pie .left-side {
  transform: rotate(162deg);
}
.pie-wrapper.progress-45 .pie .right-side {
  display: none;
}
.pie-wrapper.progress-75 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-75 .pie .half-circle {
  border-color: #8e44ad;
}
.pie-wrapper.progress-75 .pie .left-side {
  transform: rotate(270deg);
}
.pie-wrapper.progress-75 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper.progress-95 .pie {
  clip: rect(auto, auto, auto, auto);
}
.pie-wrapper.progress-95 .pie .half-circle {
  border-color: #e74c3c;
}
.pie-wrapper.progress-95 .pie .left-side {
  transform: rotate(342deg);
}
.pie-wrapper.progress-95 .pie .right-side {
  transform: rotate(180deg);
}
.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;
}
.pie-wrapper--solid:before {
  border-radius: 0 100% 100% 0 / 50%;
  content: '';
  display: block;
  height: 100%;
  margin-left: 50%;
  transform-origin: left;
}
.pie-wrapper--solid .label {
  background: transparent;
}
.pie-wrapper--solid.progress-65 {
  background: linear-gradient(to right, #e67e22 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-65:before {
  background: #e67e22;
  transform: rotate(126deg);
}
.pie-wrapper--solid.progress-25 {
  background: linear-gradient(to right, #9b59b6 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-25:before {
  background: #34495e;
  transform: rotate(-270deg);
}
.pie-wrapper--solid.progress-88 {
  background: linear-gradient(to right, #3498db 50%, #34495e 50%);
}
.pie-wrapper--solid.progress-88:before {
  background: #3498db;
  transform: rotate(43.2deg);
}

.dark ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
